.home {
  padding: 0 !important;

  .hero {
    height: 50vh;
    width: 100%;

    .speech {
      background-color: rgb(253, 253, 253);
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      border-radius: 1rem;
      box-shadow: 0 3px 5px rgba(51, 51, 51, 0.15);
      padding: 5%;
    }
  }

  .section {
    padding: 1rem 5%;

    &-title {
      text-align: left;
      color: #333;
      line-height: 1;
    }
  }

  @media screen and (max-width: 680px) {
  }
}
