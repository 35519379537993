$pri-color: rgb(92, 240, 92);

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  position: relative;
  font-family: 'poppins', sans-serif;
  font-size: 16px;

  @media screen and (max-width: 620px) {
    font-size: 14px;
  }

  scroll-behavior: smooth;
}

body {
  height: 100%;
  width: 100%;
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

.app {
  height: 100%;
  text-align: center;
  position: relative;

  // BOOKS

  .books {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    margin-top: 2rem;

    .book {
      position: relative;
      box-shadow: 0 3px 5px rgba(51, 51, 51, 0.15);
      border-radius: 0.5rem;
      flex: 200px;
      display: flex;
      flex-direction: column;
      max-width: 180px;
      gap: 1rem;
      padding-bottom: 1rem;
      text-align: left;
      line-height: 1.2;
      overflow: hidden;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 100%;
        max-height: 240px;
        object-fit: cover;
        background-color: antiquewhite;
      }

      p {
        font-size: 0.8rem;
        text-align: left;
        padding: 0 0.5rem;
        font-weight: 600;
        color: #333;
      }

      button {
        width: 100%;
        &.deleteBtn {
          background-color: rgb(199, 14, 14);
          color: #f4f4f4;
        }

        &.updateBtn {
          background-color: rgb(193, 241, 179);
          color: #333;
        }
      }

      .buttons {
        display: flex;
        width: 100%;
        gap: 0.5rem;

        button {
          flex-grow: 1;
        }

        span {
          height: 2.25rem;
          border-radius: 0.35rem;
          width: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid lightgray;
          cursor: pointer;
        }
      }
    }
  }

  // CONTAINER

  .container {
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;
    position: relative;
  }

  // LOGO

  .logo {
    color: rgba(11, 11, 11, 0.841);
    font-weight: 500;
    text-decoration: none;
    font-size: 1rem;
    line-height: 0.9;
    text-align: left;
    display: flex;
    gap: 0.4rem !important;
    align-items: center;
    flex-shrink: 0;
    flex-direction: row !important;
  }

  // HEADER

  header {
    display: flex;
    background-color: #fff;
    user-select: none;
    padding: 1rem 5%;
    height: 5rem;
    align-items: center;
    border-bottom: 1px solid lightgray;
    width: 100%;
    justify-content: space-between;

    a {
      gap: 0.5rem;
      display: flex;
    }

    .search-box {
      background-color: #fff;
      border-radius: 0.5rem;
      border: 2px solid transparent;
      border-color: lightgray;

      &:hover,
      &:focus-within {
        border-color: lightgray;

        button {
          background-color: lightgray;
        }
      }

      input {
        background-color: transparent;
        border: none;
        padding: 0.35rem 1rem;
        outline: none;
      }

      button {
        background-color: transparent;
      }
    }

    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.9rem;
      height: max-content;
      position: sticky;
      top: 8rem;
      left: 0;
      gap: 1rem;

      > div {
        gap: 1.5rem;
        display: flex;
      }

      a {
        text-decoration: none;
        font-weight: 600;
        color: rgb(58, 57, 57);
        padding-bottom: 0.25rem;

        &.active {
          border-bottom: 2px solid $pri-color;
        }
      }
    }

    .user {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      flex-direction: row-reverse;
      padding-left: 0.85rem;
      color: rgb(4, 154, 195);
    }

    .store {
      font-size: 1.5rem;
      position: relative;

      span {
        position: absolute;
        top: -0.2rem;
        right: -0.3rem;
        font-size: 0.9rem;
        background-color: #fff;
        color: #333;
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 50%;
        font-weight: 600;
      }
    }

    .avatar {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      border: 1px solid lightgray;
      background-color: #fff;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .menu-icon {
      display: none;
    }

    @media screen and (max-width: 900px) {
      .menu-icon {
        display: flex;
        padding-right: 1rem;
        font-size: 1.35rem;

        &:hover {
          cursor: pointer;
        }
      }

      nav {
        position: absolute;
        top: 5rem;
        left: 0;
        flex-direction: column;
        justify-content: start;
        padding: 2rem;
        height: calc(100vh - 5rem);
        width: 100%;
        max-width: 300px;
        background-color: #fff;
        box-shadow: 0 3px 5px rgba(51, 51, 51, 0.15);
        z-index: 10;

        font-size: 1rem;

        transform: translateX(-100%);
        transform-origin: left;
        transition: 0.3s transform ease-in-out;

        &.active {
          transform: translateX(0);
        }

        div {
          flex-direction: column;
          width: 100%;
        }
      }

      .user {
        span {
          display: none;
        }
      }

      .search-box {
        input {
          display: none;
        }
      }
    }
  }

  // MAIN

  main {
    padding: 1rem 5%;
    min-height: 90vh;
    position: relative;

    section {
      position: relative;
      min-height: 100%;
    }
  }

  .flex {
    display: flex;
  }

  // FOOTER

  footer {
    margin-top: 6rem;
    padding: 1rem 5%;
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.85);
    font-size: 0.8rem;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  button {
    border: 0;
    outline: 0;
    padding: 0.5rem 1.5rem;
    font-size: 0.965rem;
    border-radius: 0.25rem;
    font-weight: 600;
    background-color: transparent;
    border: 1px solid $pri-color;

    &:hover {
      background-color: $pri-color;
    }

    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 3rem;

    background-color: rgb(246, 231, 231);
    width: 100%;
    padding: 2rem 2rem;
    max-width: 300px;
    border-radius: 0.25rem;

    .input-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 0.25rem;

      label {
        font-size: 0.8rem;
        font-weight: bold;
        color: #333;
      }
    }

    input,
    select {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      max-width: 100%;
      border: none;
      border-bottom: 1px solid gray;
    }

    button {
      width: 100%;
      background-color: $pri-color;
      border: 1px solid transparent;
      margin-top: 0.75rem;

      &:hover {
        background-color: transparent;
        border-color: $pri-color;
      }
    }

    .error-class {
      font-size: 0.8rem;
      color: red;
      text-align: center;
    }

    .info-class {
      font-size: 0.8rem;
      text-align: center;
    }
  }

  // ERRORPAGE
  .errorPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 6rem;
    }

    p {
      font-size: 2rem;
    }

    button {
      margin-top: 3rem;
    }
  }

  // BUTTON CLASS STATE
  .btn-danger {
    background-color: rgb(199, 14, 14);
    color: #f4f4f4;
  }

  .btn-success {
    background-color: rgb(193, 241, 179);
    color: #333;
  }
}

// IMPORT COMPONENTS

@import './styles/components/Index';

// IMPORT PAGES

@import './styles/pages/Index';

@media screen and (max-width: 620px) {
  .app {
    .books {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;

      .book {
        width: 100%;
      }
    }
  }
}

article {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    width: max-content;
    margin: auto;
  }
}

.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 5%;
  width: 100%;
  max-width: 320px;
  min-height: 300px;
  margin: auto;
  box-shadow: 0 3px 5px rgba(51, 51, 51, 0.15);
  border-radius: 0.5rem;

  margin-bottom: 2rem;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile-image {
  height: 5rem;
  width: 5rem;
  box-shadow: 0 3px 5px rgba(51, 51, 51, 0.15);
  border-radius: 50%;
  overflow: hidden;
}
